import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { ProductGalleryCard } from '@/components/molecules';
import useDebounce from '@/hooks/useDebounce';

const ProductList = ({ windowProducts }) => {
  const { items } = useSelector((state) => state.products);
  const [scrollPercentaje, setScrollPercentaje] = useState(0);
  const actualFraction = useRef(1);
  const debounce = useDebounce(30);
  const handleScroll = (heightContainer) => {
    debounce(() => {
      const rowHeight = 184;
      const rowGap = 16;
      const newScrollPercentaje =
        window.scrollY / (heightContainer - (rowHeight * 2 + rowGap * 2));

      setScrollPercentaje((prevScroll) =>
        prevScroll < newScrollPercentaje ? newScrollPercentaje : prevScroll
      );
    });
  };
  useEffect(() => {
    const gridContainer = document.getElementById('grid-container');
    window.addEventListener(
      'scroll',
      () => handleScroll(gridContainer?.clientHeight),
      {
        passive: true
      }
    );
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const renderItems = () => {
    const fraction = Math.ceil(items.length / windowProducts);
    if (
      actualFraction.current < fraction &&
      scrollPercentaje >
        (windowProducts * actualFraction.current) / items.length
    ) {
      actualFraction.current += 1;
    }
    return items
      .slice(0, windowProducts * actualFraction.current)
      .map(
        (
          {
            id,
            name,
            url,
            all_prices: price,
            gender,
            brand_name: brandName,
            brand_icon: brandIcon,
            dummy_images: images,
            is_wish_product: isWishProduct,
            featured
          },
          index
        ) => (
          <ProductGalleryCard
            key={url}
            productUrl={url}
            featured={featured}
            imageSrc={images[0]?.url}
            productId={id}
            isFavorite={isWishProduct}
            color="#377DDD"
            priority={index < 9}
            cardContent={{
              gender,
              installments: `${price.installments?.quantity} x $${
                price?.installments?.value.split(',')[0]
              } s/int.`,
              price: price.sale_price || price.original,
              strikePrice: price.sale_price ? price.original : null,
              name,
              discount: price.discount,
              brandName,
              brandIcon
            }}
          />
        )
      );
  };
  return <>{renderItems()}</>;
};

ProductList.propTypes = {
  windowProducts: PropTypes.number
};
ProductList.defaultProps = {
  windowProducts: 12
};
export default ProductList;
